import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { OFFICE_365_CLIENT_ID } from "../../../../config";

import { authActions, getAuth } from "../../../../core/auth";
import BackgroundImage from "../../../../images/backgrounds/sign-in.png";
import Logo from "../../../../images/book-creator-logo-colour.png";
import Form from "../../forms/form";
import FormButton from "../../forms/form-button";
import Loader from "../../ui/loader";
import Message from "../../ui/message";

const loginRequest = { scopes: ["User.Read"] };

const Office365 = props => {
  const { auth, doOffice365Login, onOffice365Error, clearOffice365Error } = props;
  const { office365Error } = auth;

  const applicationClient = new PublicClientApplication({
    auth: {
      clientId: OFFICE_365_CLIENT_ID,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  });

  const handleResponse = resp => {
    if (resp !== null) {
      applicationClient.setActiveAccount(resp.account);
      getTokenPopup(loginRequest, resp.account).then(({ accessToken }) => {
        doOffice365Login({ token: accessToken });
      });
    }
  };

  const signIn = async () => {
    const redirectUri = `https://${window.location.host}/sign-in/office-365`;
    return applicationClient
      .loginPopup({
        ...loginRequest,
        redirectUri: redirectUri,
      })
      .then(handleResponse)
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTokenPopup = async (request, account) => {
    return await applicationClient.acquireTokenSilent(request).catch(async error => {
      if (error instanceof InteractionRequiredAuthError) {
        return applicationClient.acquireTokenPopup(request).catch(error => {
          handleError(error);
        });
      } else {
        handleError(error);
      }
    });
  };

  const handleError = error => {
    const errorMessage =
      error?.errorCode === "access_denied"
        ? "You need to allow access to your account to continue"
        : error.errorMessage;
    onOffice365Error(errorMessage);
  };

  useEffect(() => {
    applicationClient.initialize().then(() => {
      applicationClient
        .handleRedirectPromise()
        .then(handleResponse)
        .catch(err => {
          handleError(err);
        });

      signIn();
    });
    // eslint-disable-next-line
  }, [signIn]);

  const retry = e => {
    clearOffice365Error();
    signIn();
    e.preventDefault();
  };

  const containerStyle = {
    backgroundImage: `url(${BackgroundImage})`,
  };

  return (
    <div className="sign-in" style={containerStyle}>
      <Helmet title="Book Creator - Office365 login" />
      <div className="sign-in__content">
        <h1 className="sign-in__logo">
          <img src={Logo} alt="Book Creator" />
        </h1>
        {office365Error ? (
          <div>
            <Form header="Sign in with Office 365" onSubmit={retry}>
              <div className="form__content">
                <Message type="error">{office365Error}</Message>
              </div>
              <div className="form__actions">
                <FormButton flat href="/sign-in" text="Cancel" />
                <FormButton type="submit" text="Try again" />
              </div>
            </Form>
          </div>
        ) : (
          <Loader colour="white" />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createSelector(getAuth, auth => ({
  auth,
}));

const mapDispatchToProps = { ...authActions };

export default connect(mapStateToProps, mapDispatchToProps)(Office365);
